import { useState } from "react";
import MeatBallMenu from "../UI/MeatBallMenu";
import styles from "./ManageRoom.module.css";
import { AddExistingStudent } from "./AddUserModal";
import { Link } from "react-router-dom";
import {
    bulk_student_update,
    get_bulk_student,
    queryClient,
} from "../../util/http";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toSentenceCase } from "../../util/misc";
import { DIVISION_ALIAS, Toast } from "../../config";

const RoomStudents = ({ divisionId, classId }) => {
    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);

    const {
        data: students,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ["students", { class_id: classId, division_id: divisionId }],
        queryFn: get_bulk_student.bind(null, classId, divisionId),
    });
    const data = students?.data;
    const studentList = data?.data?.list;
    // console.log(studentList);

    const { mutate } = useMutation({
        mutationFn: bulk_student_update.bind(null,classId, divisionId),
        onSuccess: () => {
            Toast.fire({
                title: `Added student(s) to ${DIVISION_ALIAS.toLowerCase()}`,
                icon: "success",
            });

            queryClient.invalidateQueries({
                queryKey: ["students"],
            });
        },
        onError: (error) => {
            Toast.fire({
                title: `Failed to add student(s) to ${DIVISION_ALIAS.toLowerCase()}`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error in add division students", error);
        },
    });

    return (
        <>
            <AddExistingStudent onSave={mutate} show={show} onHide={hideModal} />
            <h4 className="text-indigo">Student Accounts</h4>
            <div className="mt-2">
                <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={showModal}
                >
                    Add existing students
                </button>
                <button className="btn btn-sm btn-secondary ms-1" type="button">
                    Change room
                </button>
                <Link
                    className="btn btn-sm btn-link ms-1"
                    type="button"
                    to="/students"
                >
                    Add new student
                </Link>
            </div>
            <div className={`table-responsive-md mt-2 ${styles.responsive}`}>
                <table
                    className={`table table-hover table-light ${styles["student-list"]}`}
                >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th className="text-center" scope="col">
                                Status
                            </th>
                            <th className="text-center" scope="col">
                                <i className="fa-solid fa-list"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles["tbody-bg"]}>
                        {isPending ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    Loading...
                                </td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    {error.name}
                                    {": "}
                                    {error.message}
                                </td>
                            </tr>
                        ) : data.code === 2 ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    No students added to this division
                                </td>
                            </tr>
                        ) : data.code === 1 ? (
                            studentList.map((student) => {
                                return (
                                    <tr key={student.id}>
                                        <th scope="row">{student.id}</th>
                                        <td>{student.username}</td>
                                        <td className="text-center">
                                            <span
                                                className={`badge fw-normal rounded-pill ${
                                                    student.status === "active"
                                                        ? "text-bg-success"
                                                        : "text-bg-danger"
                                                }`}
                                            >
                                                {toSentenceCase(student.status)}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <MeatBallMenu>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        href="#"
                                                    >
                                                        Change room
                                                    </button>
                                                </li>
                                            </MeatBallMenu>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    Unknown response!
                                </td>
                            </tr>
                        )}

                        {}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default RoomStudents;
