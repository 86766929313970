import { useQuery } from "@tanstack/react-query";
import { get_class_detail, get_division_detail } from "../../util/http";
import RoomStudents from "./RoomStudents";
import RoomTeachers from "./RoomTeachers";
import { useParams } from "react-router-dom";

const ManageRoom = () => {
    let { classId, divisionId } = useParams();
    classId = parseInt(classId);
    divisionId = parseInt(divisionId);

    const { data: classinfo } = useQuery({
        queryKey: ["classes", { class_id: classId }],
        queryFn: get_class_detail.bind(null, classId),
    });
    const classInfo = classinfo?.data?.data;

    const { data: division } = useQuery({
        queryKey: ["divisions", { division_id: divisionId }],
        queryFn: get_division_detail.bind(null, divisionId),
    });
    const divisionInfo = division?.data?.data;

    if (classInfo && divisionInfo)
        return (
            <div className="my-4">
                <h2 className="h2 mb-4">
                    Manage{" "}
                    <span className="text-light bg-sky">
                        {classInfo && classInfo.name}
                    </span>
                    {" / "}
                    <span className="text-light bg-lime">
                        {divisionInfo && divisionInfo.name}
                    </span>
                </h2>
                <RoomTeachers divisionInfo={divisionInfo} />
                <RoomStudents classId={classId} divisionId={divisionId}/>
            </div>
        );

    return (
        <div className="d-flex justify-content-center m-3">
            <div className="spinner-border " role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};
export default ManageRoom;
