import axios from "axios";
import { BASE_URL } from "../config";
import { getAuthToken } from "./auth";
import { QueryClient } from "@tanstack/react-query";

// Endpoints or resource paths
const urls = {
    register_school: "/api/admin/register/register_school",
    get_school_dashboard_stats: "api/admin/common/get_school_dashboard_stats",
    get_school_detail: "api/admin/school/get_school_detail",
    user_login: "/api/admin/admin/user_login",
    get_user_list: "/api/admin/user/get_user_list",
    add_user: "/api/admin/user/add_user",
    delete_user: "/api/admin/user/delete_user",
    update_user: "/api/admin/user/update_user",
    add_bulk_student: "/api/admin/user/add_bulk_student",
    bulk_student_update: "/api/admin/user/bulk_student_update",
    get_bulk_student: "/api/admin/user/get_bulk_student",
    get_class_list: "/api/admin/class/get_class_list",
    get_class_detail: "/api/admin/class/get_class_detail",
    add_class: "/api/admin/class/add_class",
    update_class: "/api/admin/class/update_class",
    delete_class: "/api/admin/class/delete_class",
    get_division_list: "/api/admin/division/get_division_list",
    add_division: "/api/admin/division/add_division",
    delete_division: "/api/admin/division/delete_division",
    get_division_detail: "/api/admin/division/get_division_detail",
    update_division: "/api/admin/division/update_division",
};

// List of endpoints or resources that does not require authentication ie. no need of token in the headers/
const noLoginUrls = [urls.register_school, urls.user_login];

// Create a client for react query
export const queryClient = new QueryClient();

// Axios is used in this project for requests/responses
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "api-key": "n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii",
        "accept-language": "en",
        "admin-type": "admin",
        "Content-Type": "text/plain",
        "offline-access": "true",
    },
    // timeout: 10000, //If the request takes longer than `timeout`, the request will be aborted.
});

axiosInstance.interceptors.request.use((request) => {
    console.log("Request⬆️ ", request);

    if (noLoginUrls.includes(request.url)) return request;

    const token = getAuthToken();
    if (!token) {
        window.location.href = "/logout";
        // Promise.reject("Hi");
    } else {
        request.headers.token = token;
        return request;
    }
});

axiosInstance.interceptors.response.use(
    (response) => {
        if (
            parseInt(response.data.code) === 1 ||
            parseInt(response.data.code) === 2
        ) {
            return response;
        }
        const error = new Error(response.data.message);
        error.code = response.status;
        error.name = "Server Error";
        error.request = response.request;
        error.response = response.data;
        console.error("Looks like there was a server issue. ", { error });
        return Promise.reject(error);
    },
    (error) => {
        console.log("Axios Error", error);
        return Promise.reject(error);
    }
);

const getSchoolId = () => {
    return parseInt(localStorage.getItem("school_id"));
};

// Axios request functions goes below

export const register_school = (school_data) => {
    return axiosInstance.post(urls.register_school, school_data);
};

export const get_school_dashboard_stats = () => {
    return axiosInstance.post(urls.get_school_dashboard_stats, {
        school_id: getSchoolId(),
    });
};

export const get_school_detail = () => {
    return axiosInstance.post(urls.get_school_detail, {
        school_id: getSchoolId(),
    });
};

export const user_login = (credentials) => {
    return axiosInstance.post(urls.user_login, {
        ...credentials,
        user_type: "school_admin",
    });
};

export const get_user_list = (user_type, options = undefined) => {
    if (!(user_type === "student" || user_type === "teacher"))
        throw TypeError("user_type must be 'student' or 'teacher'");
    return axiosInstance.post(urls.get_user_list, {
        ...options,
        user_type: user_type,
        school_id: getSchoolId(),
    });
};

export const add_user = (user_type, data) => {
    console.log("data", data);
    if (!(user_type === "student" || user_type === "teacher"))
        throw TypeError("user_type must be 'student' or 'teacher'");
    return axiosInstance.post(urls.add_user, {
        ...data,
        user_type: user_type,
        school_id: getSchoolId(),
    });
};

export const update_user = (user_type, data) => {
    console.log("data", data);
    if (!(user_type === "student" || user_type === "teacher"))
        throw TypeError("user_type must be 'student' or 'teacher'");
    return axiosInstance.post(urls.update_user, {
        ...data,
        user_type: user_type,
        school_id: getSchoolId(),
    });
};

export const delete_user = (user_id) => {
    return axiosInstance.post(urls.delete_user, { user_id: user_id });
};

export const add_bulk_student = (data) => {
    return axiosInstance.post(urls.add_bulk_student, {
        ...data,
        school_id: getSchoolId(),
    });
};

export const get_class_list = (data) => {
    return axiosInstance.post(urls.get_class_list, {
        page: 1,
        limit: 1000,
        sort_by: "id",
        order_by: "asc",
        school_id: getSchoolId(),
    });
};

export const get_class_detail = (class_id) => {
    return axiosInstance.post(urls.get_class_detail, {
        class_id: class_id,
        school_id: getSchoolId(),
    });
};

export const add_class = (data) => {
    return axiosInstance.post(urls.add_class, {
        ...data,
        school_id: getSchoolId(),
    });
};

export const update_class = (data) => {
    return axiosInstance.post(urls.update_class, {
        ...data,
        school_id: getSchoolId(),
    });
};

export const delete_class = (class_id) => {
    return axiosInstance.post(urls.delete_class, {
        class_id: class_id,
        school_id: getSchoolId(),
    });
};

export const get_division_list = (data = undefined) => {
    return axiosInstance.post(urls.get_division_list, {
        page: 1,
        limit: 1000,
        sort_by: "id",
        order_by: "asc",
        ...data,
        school_id: getSchoolId(),
    });
};

export const delete_division = (division_id) => {
    return axiosInstance.post(urls.delete_division, {
        division_id: division_id,
        school_id: getSchoolId(),
    });
};

export const add_division = (data) => {
    return axiosInstance.post(urls.add_division, {
        ...data,
        school_id: getSchoolId(),
    });
};

export const get_division_detail = (division_id) => {
    return axiosInstance.post(urls.get_division_detail, {
        division_id: division_id,
        school_id: getSchoolId(),
    });
};

export const update_division = (data) => {
    return axiosInstance.post(urls.update_division, {
        ...data,
        school_id: getSchoolId(),
    });
};

export const get_bulk_student = (class_id, division_id) => {
    return axiosInstance.post(urls.get_bulk_student, {
        class_id,
        division_id,
        school_id: getSchoolId(),
    });
};

export const bulk_student_update = (class_id, division_id, student_ids) => {
    return axiosInstance.post(urls.bulk_student_update, {
        class_id,
        division_id,
        student_ids,
        school_id: getSchoolId(),
    });
};
