import MeatBallMenu from "../UI/MeatBallMenu";
import styles from "./ManageStudent.module.css";
import { Alert, CLASS_ALIAS, DIVISION_ALIAS, Toast } from "../../config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    delete_user,
    get_user_list,
    queryClient,
    update_user,
} from "../../util/http";
import { toSentenceCase } from "../../util/misc";

const StudentList = () => {
    const {
        data: students,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ["students"],
        queryFn: get_user_list.bind(null, "student", {
            sort_by: "id",
            order_by: "desc",
        }),
    });
    const data = students?.data;
    const studentList = data?.data?.list;
    console.log(data);

    const { mutate } = useMutation({
        mutationFn: ({ user_id }) => delete_user(user_id),
        onSuccess: (_, { username }) => {
            Toast.fire({
                title: `Deleted ${username} successfully!`,
                icon: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["students"] });
        },
        onError: (error, { username }) => {
            Toast.fire({
                title: `Failed to delete ${username}`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error in student list", error);
        },
    });

    const { mutate: resetPassword } = useMutation({
        mutationFn: update_user.bind(null,"student"),
        onSuccess: () => {
            Toast.fire({
                title: `Password reset successful 🔓`,
                icon: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["students"] });
        },
        onError: (error) => {
            Toast.fire({
                title: `Failed to reset password !`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error password reset", error);
        },
    });

    const deleteHandler = (e) => {
        const studentId = e.target.getAttribute("data-id");
        const studentUsername = e.target.getAttribute("data-username");
        Alert.fire({
            title: "Are you sure?",
            text: `You are about to delete the user '${studentUsername}'. This action is irreversible!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                mutate({ user_id: studentId, username: studentUsername });
            }
        });
    };

    const passwordResetHandler = async (e) => {
        const studentId = e.target.getAttribute("data-id");
        const studentUsername = e.target.getAttribute("data-username");
        const { value: newPassword } = await Alert.fire({
            title: `Reset Password ${studentUsername}`,
            icon: "warning",
            input: "text",
            inputLabel: "New password",
            inputPlaceholder: "Enter your new password",
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            },
            showCancelButton: true,
            // confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Reset Password",
        });
        if (newPassword) {
            console.log("going to reset")
            resetPassword({
                user_id: studentId,
                username: studentUsername,
                password: newPassword,
            });
        }
    };

    return (
        <>
            <h4 className="text-indigo mt-5">Student Accounts</h4>
            <div className={`table-responsive-md mt-2 ${styles.responsive}`}>
                <table
                    className={`table table-hover table-light ${styles["student-list"]}`}
                >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Password</th>
                            <th scope="col">{CLASS_ALIAS}</th>
                            <th scope="col">{DIVISION_ALIAS}</th>
                            <th className="text-center" scope="col">
                                Status
                            </th>
                            <th className="text-center" scope="col">
                                <i className="fa-solid fa-list"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles["tbody-bg"]}>
                        {isPending ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    Loading...
                                </td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    {error.name}
                                    {": "}
                                    {error.message}
                                </td>
                            </tr>
                        ) : data.code === 2 ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    No data to display
                                </td>
                            </tr>
                        ) : data.code === 1 ? (
                            studentList.map((student, index) => {
                                return (
                                    <tr key={student.id}>
                                        <th scope="row">{student.id}</th>
                                        <td>{student.username}</td>
                                        <td>{student.password}</td>
                                        <td>{student.class}</td>
                                        <td>{student.division}</td>
                                        <td className="text-center">
                                            <span
                                                className={`badge fw-normal rounded-pill ${
                                                    student.status === "active"
                                                        ? "text-bg-success"
                                                        : "text-bg-danger"
                                                }`}
                                            >
                                                {toSentenceCase(student.status)}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <MeatBallMenu>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        // onClick={onEdit.bind(
                                                        //     null,
                                                        //     {
                                                        //         id: student.id,
                                                        //         name: student.name,
                                                        //         email: teacher.email,
                                                        //         phone: teacher.phone_number,
                                                        //         username:
                                                        //             teacher.username,
                                                        //         password:
                                                        //             teacher.password,
                                                        //     }
                                                        // )}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        data-id={student.id}
                                                        data-username={
                                                            student.username
                                                        }
                                                        className="dropdown-item"
                                                        onClick={passwordResetHandler}
                                                    >
                                                        Reset Password
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        data-id={student.id}
                                                        data-username={
                                                            student.username
                                                        }
                                                        onClick={deleteHandler}
                                                        className="dropdown-item"
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            </MeatBallMenu>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    Unknown response!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default StudentList;
