import { redirect, useSubmit } from "react-router-dom";
import { removeAuthData } from "../util/auth";
import { queryClient } from "../util/http";

export const action = () => {
    console.log("Logging Out!");
    removeAuthData();
    queryClient.removeQueries(); // Remove every query in the cache
    return redirect("/");
};

const Logout = () => {
    console.log("Logout component");
    const submit = useSubmit();
    submit(null, { action: "/logout", method: "POST" });
    return null;
};

export default Logout;
