import { useRef, useState } from "react";
import TeacherInfoForm from "./TeacherInfoForm";
import TeacherList from "./TeacherList";

const ManageTeacher = () => {
    const [resetTeacherInfoFormKey, setResetTeacherInfoFormKey] =
        useState(false);
    const [editData, setEditData] = useState();
    const teacherInfoForm = useRef(null);

    const onEditHandler = (editData) => {
        if (!(editData && editData.id))
            throw Error(
                "editData passed to onEdit must be an object with id key."
            );
        resetTeacherInfoForm();
        setEditData(editData);
        teacherInfoForm.current.scrollIntoView(false);
    };

    const resetTeacherInfoForm = () => {
        setEditData(undefined);
        setResetTeacherInfoFormKey(!resetTeacherInfoFormKey);
    };

    return (
        <div className="my-4">
            <h2 className="h2 mb-4">Manage Teacher Accounts</h2>
            <TeacherInfoForm
                key={resetTeacherInfoFormKey}
                reset={resetTeacherInfoForm}
                editData={editData}
                ref={teacherInfoForm}
            />
            <TeacherList onEdit={onEditHandler} />
        </div>
    );
};
export default ManageTeacher;
