import Jumbotron from "./UI/Jumbotron";
import styles from "./LandingPage.module.css";
import { useContext, useLayoutEffect } from "react";
import StyleClassContext from "../store/StylesContext";
import { Link, useRouteLoaderData } from "react-router-dom";
import Dashboard from "./Dashboard";

const Welcome = () => {
    const setPageBackgroundClass = useContext(StyleClassContext);
    const jumbotronSpacingClasses = " mt-3 mx-auto";

    useLayoutEffect(() => {
        setPageBackgroundClass(styles.bg);
        return () => {
            setPageBackgroundClass("");
        };
    }, [setPageBackgroundClass]);
    return (
        <>
            <Jumbotron
                className={styles.jumbotron + jumbotronSpacingClasses}
                backgroundColor
            >
                <h1 className="">
                    Interested in CyberSmarties ?{" "}
                    <span className="text-primary">Get Started Today</span>
                </h1>
                <Link
                    type="button"
                    className={`btn btn-lg btn-primary ${styles.signup}`}
                    to="/register"
                >
                    Register School
                </Link>
            </Jumbotron>

            <Jumbotron
                className={styles.jumbotron + jumbotronSpacingClasses}
                backgroundColor
            >
                <h1>
                    Already a member ?
                    <Link
                        type="button"
                        className="btn btn-lg btn-success ms-3 mb-1"
                        to="/login"
                    >
                        Login
                    </Link>
                </h1>
            </Jumbotron>
        </>
    );
};

const LandingPage = () => {
    const loggedIn = useRouteLoaderData("root") ? true : false;
    if(loggedIn) return <Dashboard/>
    else return <Welcome/>

};

export default LandingPage;
