import { useContext, useLayoutEffect } from "react";
import styles from "./Analytics.module.css";
import StyleClassContext from "../../store/StylesContext";

const AnalyticsPage = () => {
    const setPageBackgroundClass = useContext(StyleClassContext);
    useLayoutEffect(() => {
        setPageBackgroundClass(styles.bg);
        return () => {
            setPageBackgroundClass("");
        };
    }, [setPageBackgroundClass]);
    return <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
            <div className="col-8 text-white justify-content-center"><center><h1 className="rounded-pill text-white bg-black">Coming Soon</h1></center></div>
            <br />
            <button type="button" className="btn btn-lg rounded-pill text-white bg-indigo col-6">Click to view report</button>
            <button type="button" className="btn btn-lg rounded-pill text-white bg-indigo col-6">Click to download report</button>
        </div>
    </div>;
};

export default AnalyticsPage;
