import { Alert, CLASS_ALIAS, COMMON_CLASS_ALIAS, Toast } from "../../config";
import styles from "./ManageClassDivision.module.css";
import MeatBallMenu from "../UI/MeatBallMenu";
import { useMutation, useQuery } from "@tanstack/react-query";
import { delete_class, get_class_list, queryClient } from "../../util/http";

const ClassList = ({ selectedClassId, onClassSelect, onClassEdit }) => {
    const {
        data: classes,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ["classes"],
        queryFn: get_class_list,
    });

    const data = classes?.data;
    const classList = data?.data?.list;

    // console.log(data, classList);

    const { mutate } = useMutation({
        mutationFn: ({ class_id }) => delete_class(class_id),
        onSuccess: (_, { class_name }) => {
            Toast.fire({
                title: `Deleted ${class_name} successfully!`,
                icon: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["classes"] });
        },
        onError: (error, { class_name }) => {
            Toast.fire({
                title: `Failed to delete ${class_name}`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error in class list", error);
        },
    });

    const deleteHandler = (e) => {
        const classId = e.target.getAttribute("data-id");
        const className = e.target.getAttribute("data-name");
        Alert.fire({
            title: "Are you sure?",
            text: `You are about to delete the class '${className}'. This action is irreversible!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                mutate({ class_id: classId, class_name: className });
            }
        });
    };

    const classClickHandler = (e) => {
        const classId = parseInt(e.target.parentElement.id);
        onClassSelect(classId);
    };

    return (
        <>
            <h4 className="text-indigo mt-4 bg-light-transparent d-inline-block">
                {CLASS_ALIAS} List
            </h4>
            <div className={`table-responsive-md ${styles.responsive}`}>
                <table
                    className={`table table-hover table-light ${styles["table-design-1"]} ${styles["class-list"]}`}
                >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">{COMMON_CLASS_ALIAS}</th>
                            <th className="text-center" scope="col">
                                <i className="fa-solid fa-list"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles["tbody-bg"]}>
                        {isPending ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    Loading...
                                </td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    {error.name}
                                    {": "}
                                    {error.message}
                                </td>
                            </tr>
                        ) : data.code === 2 ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    No data to display
                                </td>
                            </tr>
                        ) : data.code === 1 ? (
                            classList.map((class_) => {
                                const className__is_active =
                                    selectedClassId !== null &&
                                    parseInt(selectedClassId) === class_.id
                                        ? "table-active"
                                        : "";
                                return (
                                    <tr
                                        key={class_.id}
                                        id={class_.id}
                                        className={className__is_active}
                                    >
                                        <th
                                            scope="row"
                                            onClick={classClickHandler}
                                        >
                                            {class_.id}
                                        </th>
                                        <td onClick={classClickHandler}>
                                            {class_.name}
                                        </td>
                                        <td onClick={classClickHandler}>
                                            {class_.permanent_classes? class_.permanent_classes
                                                .split(",")
                                                .map((commonClassNumber) => {
                                                    return (
                                                        <span
                                                            key={
                                                                commonClassNumber
                                                            }
                                                            className="badge text-bg-secondary fw-normal mx-1"
                                                        >
                                                            {CLASS_ALIAS}{" "}
                                                            {commonClassNumber}
                                                        </span>
                                                    );
                                                }):''}
                                        </td>
                                        <td className="text-center">
                                            <MeatBallMenu>
                                                <li>
                                                    <button
                                                        className="dropdown-item edit"
                                                        type="button"
                                                        onClick={onClassEdit.bind(
                                                            null,
                                                            {
                                                                id: class_.id,
                                                                name: class_.name,
                                                                permanent_classes:
                                                                    class_.permanent_classes,
                                                            }
                                                        )}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item delete"
                                                        type="button"
                                                        onClick={deleteHandler}
                                                        data-id={class_.id}
                                                        data-name={class_.name}
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            </MeatBallMenu>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    Unknown response!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ClassList;
