import styles from "./RegisterSchool.module.css";
import FloatingInput from "../UI/FloatingInput";
import { useReducer, useState } from "react";
import validator from "validator";
import parsePhoneNumber from "libphonenumber-js";
import { useMutation } from "@tanstack/react-query";
import { DEFAULT_REGION, REGIONS } from "../../config";
import { register_school } from "../../util/http";
import { Alert } from "../../config";
import { useNavigate } from "react-router-dom";

const passwordReducer = (state, action) => {
    let newState = state;
    switch (action.type) {
        case "UPDATE_PASSWORD": {
            const passwordIsValid = validator.isStrongPassword(action.value, {
                minLength: 6,
                minUppercase: 1,
                minSymbols: 1,
                minNumbers: 0,
            });
            newState = {
                ...state,
                passwordIsValid: passwordIsValid,
                password: action.value,
            };

            if (!passwordIsValid)
                newState.passwordFeedback =
                    "Password must contain minimum 6 characters 1 capital letter and 1 special character";
            else newState.passwordFeedback = null;

            if (newState.repeatPassword && newState.repeatPassword.length) {
                const repeatPasswordIsValid = validator.equals(
                    action.value,
                    newState.repeatPassword
                );
                newState.repeatPasswordIsValid = repeatPasswordIsValid;
                if (!repeatPasswordIsValid)
                    newState.repeatPasswordFeedback = "Passwords do not match";
                else newState.repeatPasswordFeedback = null;
            }

            return newState;
        }
        case "UPDATE_REPEAT_PASSWORD": {
            const repeatPasswordIsValid = validator.equals(
                action.value,
                state.password
            );
            newState = {
                ...state,
                repeatPassword: action.value,
                repeatPasswordIsValid: repeatPasswordIsValid,
            };
            if (!repeatPasswordIsValid)
                newState.repeatPasswordFeedback = "Passwords do not match";
            else newState.repeatPasswordFeedback = null;
            return newState;
        }
        default:
            throw Error("Unknown action: " + action.type);
    }
};

const RegisterSchoolForm = (props) => {
    const [schoolName, setSchoolName] = useState("");
    const [schoolID, setSchoolID] = useState("");
    const [email, setEmail] = useState({ value: "", isValid: undefined });
    const [phone, setPhone] = useState({ value: "", isValid: undefined });
    const [region, setRegion] = useState(DEFAULT_REGION.id);
    const [website, setWebsite] = useState("");
    const [address, setAddress] = useState("");
    const [username, setUsername] = useState({ value: "", isValid: undefined });
    const [password, dispatchPassword] = useReducer(passwordReducer, {
        password: "",
        repeatPassword: "",
        passwordIsValid: undefined,
        repeatPasswordIsValid: undefined,
    });

    const navigate = useNavigate();

    const { mutate, isPending, isPaused } = useMutation({
        mutationFn: register_school,
        onError: (error) => {
            Alert.fire({
                title: "Failed to register school",
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
        },
        onSuccess: () => {
            Alert.fire({
                title: "Welcome to the future 🚀 ",
                text: "You are now part of Cybersmarties. Please wait while we unlock 🔓 your account.",
                icon: "success",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/login");
                }
            });
        },
    });

    const formSubmitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !email.isValid ||
            !phone.isValid ||
            !username.isValid ||
            !password.passwordIsValid ||
            !password.repeatPasswordIsValid
        )
            return;

        const payload = {
            school_code: schoolID,
            name: schoolName,
            email: email.value,
            country_code: "NA",
            phone_number: phone.value,
            location: address,
            username: username.value,
            password: password.password,
        };

        if (validator.isURL(website)) payload.website_url = website;
        mutate(payload);
    };

    const phoneChangeHandler = (e) => {
        setPhone((prevState) => {
            return { ...prevState, value: e.target.value };
        });
    };

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const checkPhoneValidForRegion = (phone, regionIndex = region) => {
        const index = parseInt(regionIndex);
        let countryCode = undefined;
        console.log(`Checking phone`, isNumber(phone), regionIndex);
        // Checks whether index is a valid integer
        if (isNumber(phone)) {
            setPhone((prevState) => {
                return {
                    ...prevState,
                    value: phone,
                };
            });
            return true;
        } else {
            return false;
        }
        if (!isNaN(index)) {
            if (REGIONS[index]) {
                countryCode = REGIONS[index].countryCode;
            }
        }

        const phoneNumber = parsePhoneNumber(phone, {
            defaultCountry: countryCode,
            extract: false,
        });
        if (!phoneNumber) return false;
        if (countryCode && phoneNumber.country !== countryCode) return false;
        const isValid = phoneNumber.isValid();

        if (isValid) {
            setPhone((prevState) => {
                return {
                    ...prevState,
                    value: phoneNumber.formatInternational(),
                };
            });
        }
        return isValid;
    };

    const phoneBlurHandler = () => {
        setPhone((prevState) => {
            const isValid = checkPhoneValidForRegion(prevState.value);
            let returnValue = {
                ...prevState,
                isValid: isValid,
            };
            if (!isValid)
                returnValue.feedback =
                    "Please enter a valid phone number for the region";
            else returnValue.feedback = null;
            return returnValue;
        });
    };

    const emailBlurHandler = () => {
        setEmail((prevState) => {
            return {
                ...prevState,
                isValid: validator.isEmail(prevState.value),
            };
        });
    };

    const regionChangeHandler = (e) => {
        setRegion(e.target.value);
        setPhone((prevState) => {
            if (!prevState.value.length) return prevState;
            const isValid = checkPhoneValidForRegion(
                prevState.value,
                e.target.value
            );
            let returnValue = {
                ...prevState,
                isValid: isValid,
            };
            if (!isValid)
                returnValue.feedback =
                    "Please enter a valid phone number for the region";
            else returnValue.feedback = null;
            return returnValue;
        });
    };

    const usernameChangeHandler = (e) => {
        const isValid =
            validator.isAlphanumeric(e.target.value, "en-US", {
                ignore: "_",
            }) || validator.isEmail(e.target.value);
        setUsername({
            value: e.target.value,
            isValid: isValid,
            feedback: isValid
                ? null
                : "Username should be alphanumeric or email",
        });
    };

    const passwordChangeHandler = (e) => {
        dispatchPassword({ type: "UPDATE_PASSWORD", value: e.target.value });
    };

    return (
        <form className="text-center" onSubmit={formSubmitHandler}>
            <div className="row mb-4">
                <div className="col-sm-5">
                    <FloatingInput
                        type="text"
                        className="mb-4"
                        id="registerInputSchoolCode"
                        placeholder="Unique identifier of the school"
                        label="School ID/Code"
                        value={schoolID}
                        onChange={(e) => {
                            setSchoolID(e.target.value);
                        }}
                        required={true}
                    />
                </div>
                <div className="col-sm-7">
                    <FloatingInput
                        type="text"
                        className="mb-4"
                        id="registerInputSchoolName"
                        placeholder="Your School Name"
                        label="School Name"
                        value={schoolName}
                        onChange={(e) => {
                            setSchoolName(e.target.value);
                        }}
                        required={true}
                    />
                </div>
                <div className="col-sm-5">
                    <div className="form-floating mb-4">
                        <select
                            className="form-select"
                            id="registerInputRegion"
                            aria-label="Select Cybersmarties region"
                            value={region}
                            required={true}
                            onChange={regionChangeHandler}
                        >
                            <option value="" disabled>
                                Select region
                            </option>
                            
                            {REGIONS.map((region) => {
                                return (
                                    <option value={region.id} key={region.id}>
                                        {region.name}
                                    </option>
                                );
                            })}
                        </select>
                        <label htmlFor="registerInputRegion">Region</label>
                    </div>
                </div>
                <div className="col-sm-7">
                    <FloatingInput
                        type="email"
                        className="mb-4"
                        id="registerInputEmail"
                        placeholder="Your school email"
                        label="Email"
                        value={email.value}
                        valid={email.isValid}
                        onChange={(e) => {
                            setEmail((prevState) => {
                                return { ...prevState, value: e.target.value };
                            });
                        }}
                        onBlur={emailBlurHandler}
                        required={true}
                    />
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-sm-6">
                    <FloatingInput
                        type="tel"
                        className="mb-4"
                        id="registerInputPhone"
                        placeholder="School's contact number"
                        label="Phone"
                        value={phone.value}
                        onChange={phoneChangeHandler}
                        onBlur={phoneBlurHandler}
                        required={true}
                        valid={phone.isValid}
                        feedback={phone.feedback}
                    />
                    <FloatingInput
                        type="url"
                        className="mb-4 mb-sm-0"
                        id="registerInputWebsite"
                        placeholder="Schools's website"
                        label="Website"
                        value={website}
                        onChange={(e) => {
                            setWebsite(e.target.value);
                        }}
                    />
                </div>
                <div className="col-sm-6">
                    <div className="form-floating h-100">
                        <textarea
                            className="form-control h-100"
                            placeholder="Address"
                            id="registerInputAddress"
                            value={address}
                            onChange={(e) => {
                                setAddress(e.target.value);
                            }}
                            required={true}
                        ></textarea>
                        <label htmlFor="registerInputAddress">Address</label>
                    </div>
                </div>
            </div>
            <FloatingInput
                type="text"
                className={`mb-4 ${styles.maxWidth}`}
                id="registerInputUsername"
                placeholder="Username"
                label="Username"
                value={username.value}
                onChange={usernameChangeHandler}
                valid={username.isValid}
                feedback={username.feedback}
                required={true}
            />
            <FloatingInput
                type="password"
                className={`mb-4 ${styles.maxWidth}`}
                id="registerInputPassword"
                placeholder="Password"
                label="Password"
                value={password.password}
                valid={password.passwordIsValid}
                feedback={password.passwordFeedback}
                onChange={passwordChangeHandler}
                required={true}
            />
            <FloatingInput
                type="password"
                className={`mb-5 ${styles.maxWidth}`}
                id="registerInputRepeatPassword"
                placeholder="Repeat your password"
                label="Repeat your password"
                value={password.repeatPassword}
                valid={password.repeatPasswordIsValid}
                feedback={password.repeatPasswordFeedback}
                onChange={(e) => {
                    dispatchPassword({
                        type: "UPDATE_REPEAT_PASSWORD",
                        value: e.target.value,
                    });
                }}
                required={true}
            />
            <button
                className="btn btn-primary w-100 py-2"
                type="submit"
                disabled={isPending}
            >
                {!isPending
                    ? "Register"
                    : !isPaused
                    ? "Registering..."
                    : "Offline"}
            </button>
        </form>
    );
};

export default RegisterSchoolForm;
