import { useRef, useState } from "react";
import { CLASS_ALIAS, DIVISION_ALIAS } from "../../config";
import ClassList from "./ClassList";
import DivisionInfoForm from "./DivisionInfoForm";
import DivisionList from "./DivisionList";
import ClassInfoForm from "./ClassInfoForm";

const ManageClassDivision = () => {
    const [resetClassInfoFormKey, setResetClassInfoFormKey] = useState(false);
    const [resetDivisionInfoFormKey, setResetDivisionInfoFormKey] =
        useState(false);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [editClassData, setEditClassData] = useState();
    const [editDivisionData, setEditDivisionData] = useState();

    const classInfoFormRef = useRef(null);
    const divisionInfoFormRef = useRef(null);

    console.log(resetClassInfoFormKey);

    const resetClassInfoForm = () => {
        setEditClassData(undefined);
        setResetClassInfoFormKey(!resetClassInfoFormKey);
    };

    const resetDivisionInfoForm = () => {
        setEditDivisionData(undefined);
        setResetDivisionInfoFormKey(!resetDivisionInfoFormKey);
    };

    const classSelectHandler = (selectedClassIdData) => {
        console.log(selectedClassIdData === selectedClassId);
        if (selectedClassIdData === selectedClassId) setSelectedClassId(null);
        else setSelectedClassId(selectedClassIdData);
    };

    const classEditHandler = (editData) => {
        if (!editData?.id)
            throw Error(
                "editData passed to onClassEdit must be an object with id key."
            );
        console.log(editData);
        resetClassInfoForm();
        setEditClassData(editData);
        classInfoFormRef.current.scrollIntoView(false);
    };

    const divisionEditHandler = (editData) => {
        if (!editData?.id)
            throw Error(
                "editData passed to onDivisionEdit must be an object with id key."
            );
        console.log(editData);
        resetDivisionInfoForm();
        setEditDivisionData(editData);
        classInfoFormRef.current.scrollIntoView(false);
    };

    return (
        <div className="my-4">
            <h2 className="h2 mb-4">
                Manage {CLASS_ALIAS} {DIVISION_ALIAS}
            </h2>
            <div className="row">
                <div className={selectedClassId === null ? "col" : "col"}>
                    <ClassInfoForm
                        key={resetClassInfoFormKey}
                        reset={resetClassInfoForm}
                        editData={editClassData}
                        ref={classInfoFormRef}
                    />
                    <ClassList
                        selectedClassId={selectedClassId}
                        onClassSelect={classSelectHandler}
                        onClassEdit={classEditHandler}
                    />
                </div>
                {selectedClassId !== null && (
                    <div className="col-md-5 col-lg-4">
                        <DivisionInfoForm
                            key={resetDivisionInfoFormKey}
                            reset={resetDivisionInfoForm}
                            classId={selectedClassId}
                            editData={editDivisionData}
                            ref={divisionInfoFormRef}
                        />
                        <DivisionList
                            classId={selectedClassId}
                            onDivisionEdit={divisionEditHandler}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageClassDivision;
