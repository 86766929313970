import Navbar from "./Navbar";
import classes from "./Errors.module.css";
import { Link } from "react-router-dom";

export const MainErrorPage = () => {
    return (
        <>
            <Navbar />
            <main
                className="bg flex-fill"
                style={{ marginTop: "68px", minWidth: "100%" }}
            >
                <div className="container d-flex h-100 justify-content-center align-items-center">
                    <div className={classes.notfound}>
                        <h1 className={classes["notfound-404"]}>404</h1>
                        <h2>Oops! This Page Could Not Be Found</h2>
                        <p className="bg-light-transparent rounded">
                            Sorry but the page you are looking for does not
                            exist, have been removed, name changed or is
                            temporarily unavailable.
                        </p>
                        <Link className="rounded-1" to="/">Go To Homepage</Link>
                    </div>
                </div>
            </main>
        </>
    );
};
