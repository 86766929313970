import { forwardRef, useState } from "react";
import FloatingInput from "../UI/FloatingInput";
import Select from "react-select";
import { CLASS_ALIAS, COMMON_CLASS_ALIAS, Toast } from "../../config";
import { useMutation } from "@tanstack/react-query";
import { add_class, queryClient, update_class } from "../../util/http";

const commonClassOption = (commonClassNumber) => ({
    value: parseInt(commonClassNumber),
    label: `${CLASS_ALIAS} ${parseInt(commonClassNumber)}`,
});

const commonClassOptions = Array.from({ length: 10 }, (_, i) =>
    commonClassOption(i + 1)
);

const ClassInfoForm = forwardRef(({ reset, editData = undefined }, ref) => {
    const editId = editData?.id;
    const initName = () => (editId && editData.name ? editData.name : "");
    const initCommonClasses = () =>
        editId && editData.permanent_classes
            ? editData.permanent_classes.split(",").map(commonClassOption)
            : [];

    const [name, setName] = useState(initName);
    const [commonClasses, setCommonClasses] = useState(initCommonClasses);
    const [addButtonMouseOver, setAddButtonMouseOver] = useState(false);

    const mutationFn = (data) =>
        editId ? update_class(data) : add_class(data);

    const mutationErrorHandler = (error) => {
        if (editId) {
            Toast.fire({
                title: `Failed to update ${CLASS_ALIAS.toLowerCase()}!`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error while editing class", error);
        } else {
            Toast.fire({
                title: `Failed to create ${CLASS_ALIAS.toLowerCase()}!`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            queryClient.invalidateQueries({
                queryKey: ["classes"],
            });
            console.log("error while adding class", error);
        }
    };

    const mutationSuccessHandler = (response) => {
        const { name } = JSON.parse(response.config.data);
        if (editId) {
            Toast.fire({
                title: `Updated ${CLASS_ALIAS.toLowerCase()} '${name}' 🌟`,
                icon: "success",
            });
        } else {
            Toast.fire({
                title: `Successfully created ${CLASS_ALIAS.toLowerCase()} '${name}' 🌟`,
                icon: "success",
            });
        }
        console.log(response);
        reset();
        queryClient.invalidateQueries({
            queryKey: ["classes"],
        });
    };

    const { mutate, isPending, isPaused } = useMutation({
        mutationFn: mutationFn,
        onError: mutationErrorHandler,
        onSuccess: mutationSuccessHandler,
    });

    const commonClassesSelectChangeHandler = (newValue) => {
        setCommonClasses(newValue);
    };

    const formSubmitHandler = (e) => {
        e.preventDefault();
        if (!name.trim().length || !commonClasses || !commonClasses.length)
            return;

        const permanent_classes = commonClasses
            .map((commonClass) => commonClass.value)
            .toString();

        const payload = { name: name, permanent_classes: permanent_classes };

        if (editId) payload.class_id = editId;
        console.log(payload);
        mutate(payload);
    };

    return (
        <div ref={ref} className={`card card-bg-1 rounded-3 border-2 my-4`}>
            <div className="card-body">
                <h4 className="card-title text-primary ">
                    Create {CLASS_ALIAS}
                </h4>
                <form onSubmit={formSubmitHandler}>
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 mb-3">
                            <FloatingInput
                                label="Name"
                                type="text"
                                id="classInfoName"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-8 col-lg-7 mb-3">
                            <Select
                                isMulti
                                name="commonClasses"
                                options={commonClassOptions}
                                value={commonClasses}
                                classNamePrefix="react-select-bootstrap"
                                placeholder={`Select ${COMMON_CLASS_ALIAS.toLowerCase()}`}
                                onChange={commonClassesSelectChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <button
                                className={`btn btn-secondary me-2 `}
                                onClick={reset}
                                type="button"
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                className={`btn btn-primary`}
                                onMouseEnter={(e) =>
                                    setAddButtonMouseOver(true)
                                }
                                onMouseLeave={(e) =>
                                    setAddButtonMouseOver(false)
                                }
                                disabled={isPending}
                            >
                                {!isPending ? (
                                    <>
                                        <i
                                            className={`fa-solid ${
                                                editId
                                                    ? "fa-pencil fa-sm"
                                                    : "fa-plus"
                                            } ${
                                                addButtonMouseOver && "fa-beat"
                                            }`}
                                            style={{
                                                verticalAlign: "-0.06rem",
                                            }}
                                        ></i>{" "}
                                        {editId ? "Edit" : "Create"}{" "}
                                        {CLASS_ALIAS.toLowerCase()}
                                    </>
                                ) : !isPaused ? (
                                    editId ? (
                                        "Editing..."
                                    ) : (
                                        "Creating..."
                                    )
                                ) : (
                                    "Offline"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default ClassInfoForm;
