
const Jumbotron = ({ children, className, backgroundColor=false, py=5 }) => {
    return (
        <div className={`p-4 mb-4 ${backgroundColor === false ? 'bg-body-secondary': ''} rounded-3 ${className}`}>
            <div className={`container-fluid py-${py}`}>
                {children}
            </div>
        </div>
    );
};

export default Jumbotron
