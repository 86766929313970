import { redirect } from "react-router-dom";
import isJWT from "validator/lib/isJWT";
import { TOKEN_EXPIRE_IN_HOURS, TOKEN_EXPIRE_IN_MINUTES } from "../config";

export const setAuthData = (data) => { 
    localStorage.setItem("username", data.name);
    // Takes the first school to manage
    localStorage.setItem("school_id", data.schools[0].school_id);
    localStorage.setItem("school_name", data.schools[0].school_name); 

    localStorage.setItem("token", data.token);
    //Calculate token expiration datetime and save it in localStorage.
    const token_expiration = new Date();
    token_expiration.setHours(
        token_expiration.getHours() + TOKEN_EXPIRE_IN_HOURS
    ); // Add TOKEN_EXPIRE_IN_HOUR hours to current time.
    token_expiration.setMinutes(
        token_expiration.getMinutes() + TOKEN_EXPIRE_IN_MINUTES
    ); // Add TOKEN_EXPIRE_IN_MINUTES minutes to current time.
    localStorage.setItem("token_expiration", token_expiration.toISOString()); // localStorage needs string
};

export const removeAuthData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("token_expiration");
    localStorage.removeItem("school_id");
    localStorage.removeItem("school_name");
};

// Returns time in milliseconds left for the token to expire. -ve value means token expired.
export const getTokenExpiration = () => {
    const storedTokenExpiration = localStorage.getItem("token_expiration");
    if (!storedTokenExpiration) return null;
    const token_expiration = new Date(storedTokenExpiration);
    const now = new Date();
    const milliseconds_left = token_expiration.getTime() - now.getTime();
    return milliseconds_left;
};

// Function to check if user is authenticated.
export const getAuthToken = () => {
    console.log("Auth check");
    const token = localStorage.getItem("token");
    if (!token || typeof token !== "string" || !isJWT(token)) return null;
    return token;
};

export const checkAuthLoader = () => {
    // console.log("checkAuthLoader");
    if (!getAuthToken()) return redirect("/login");
    // Fallback - loader must return something
    return null;
};
