import { forwardRef, useState } from "react";
import FloatingInput from "../UI/FloatingInput";
import { DIVISION_ALIAS, Toast } from "../../config";
import { useMutation } from "@tanstack/react-query";
import { add_division, queryClient, update_division } from "../../util/http";

const DivisionInfoForm = forwardRef(
    ({ classId, reset, editData = undefined }, ref) => {
        const editId = editData?.id;
        const initName = () => (editId && editData.name ? editData.name : "");

        const [addButtonMouseOver, setAddButtonMouseOver] = useState(false);
        const [name, setName] = useState(initName);

        const mutationFn = (data) =>
            editId ? update_division(data) : add_division(data);

        const mutationErrorHandler = (error) => {
            if (editId) {
                Toast.fire({
                    title: `Failed to update ${DIVISION_ALIAS.toLowerCase()}!`,
                    text: `${error.name}: ${error.message}`,
                    icon: "error",
                });
                console.log("error while editing division", error);
            } else {
                Toast.fire({
                    title: `Failed to create ${DIVISION_ALIAS.toLowerCase()}!`,
                    text: `${error.name}: ${error.message}`,
                    icon: "error",
                });
                console.log("error while adding division", error);
            }
            queryClient.invalidateQueries({
                queryKey: ["divisions", { class_id: classId }],
            });
        };

        const mutationSuccessHandler = (response) => {
            const { name } = JSON.parse(response.config.data);
            if (editId) {
                Toast.fire({
                    title: `Updated ${DIVISION_ALIAS.toLowerCase()} '${name}' 🌟`,
                    icon: "success",
                });
            } else {
                Toast.fire({
                    title: `Successfully created ${DIVISION_ALIAS.toLowerCase()} '${name}' 🌟`,
                    icon: "success",
                });
            }
            console.log(response);
            reset();
            queryClient.invalidateQueries({
                queryKey: ["divisions", { class_id: classId }],
            });
        };

        const { mutate, isPending, isPaused } = useMutation({
            mutationFn: mutationFn,
            onError: mutationErrorHandler,
            onSuccess: mutationSuccessHandler,
        });

        const formSubmitHandler = (e) => {
            e.preventDefault();
            if (!name || !name.trim().length) return;
            const payload = { name: name, class_id: classId };
            if (editId) payload.division_id = editId;
            console.log(payload);
            mutate(payload);
        };

        return (
            <div ref={ref} className={`card card-bg-1 rounded-3 border-2 my-4`}>
                <div className="card-body">
                    <h4 className="card-title text-primary ">
                        Create {DIVISION_ALIAS}
                    </h4>
                    <form onSubmit={formSubmitHandler}>
                        <div className="row">
                            <div className="col-11 mb-3">
                                <FloatingInput
                                    label="Name"
                                    type="text"
                                    id="divisionInfoName"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex justify-content-end">
                                <button
                                    className={`btn btn-secondary me-2 `}
                                    onClick={reset}
                                    type="button"
                                >
                                    Reset
                                </button>
                                <button
                                    type="submit"
                                    className={`btn btn-primary`}
                                    onMouseEnter={(e) =>
                                        setAddButtonMouseOver(true)
                                    }
                                    onMouseLeave={(e) =>
                                        setAddButtonMouseOver(false)
                                    }
                                    disabled={isPending}
                                >
                                    {!isPending ? (
                                        <>
                                            <i
                                                className={`fa-solid ${
                                                    editId
                                                        ? "fa-pencil fa-sm"
                                                        : "fa-plus"
                                                } ${
                                                    addButtonMouseOver &&
                                                    "fa-beat"
                                                }`}
                                                style={{
                                                    verticalAlign: "-0.06rem",
                                                }}
                                            ></i>{" "}
                                            {editId ? "Edit" : "Create"}{" "}
                                            {DIVISION_ALIAS.toLowerCase()}
                                        </>
                                    ) : !isPaused ? (
                                        editId ? (
                                            "Editing..."
                                        ) : (
                                            "Adding..."
                                        )
                                    ) : (
                                        "Offline"
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
);

export default DivisionInfoForm;
