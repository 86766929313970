import styles from "./RegisterSchool.module.css";
import cover from "../../assets/images/middle_school.jpg";
import RegisterSchoolForm from "./RegisterSchoolForm";

const RegisterSchool = () => {
    return (
        <div className="row my-5 justify-content-center">
            <div className="col-md-11 col-lg-8 col-xl-7">
                <div className={`card rounded-3 ${styles.card}`}>
                    <img
                        src={cover}
                        className={`card-img-top ${styles.img}`}
                        alt="Cover"
                    />
                    <div className="card-body p-4 p-md-5">
                        <h2 className="card-title mb-4">
                            Register your school
                        </h2>
                        <RegisterSchoolForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterSchool;
