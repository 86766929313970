import { useEffect, useRef } from "react";
import { subscribe, unsubscribe } from "../../util/events";
import { createPortal } from "react-dom";

const bootstrap = window.bootstrap;
const Modal = ({
    id,
    title,
    className,
    modalDialogCentered = false,
    show = false,
    onHide,
    staticBackdrop = false,
    keyboard = true,
    focus = true,
    children,
}) => {
    const modal = useRef();

    useEffect(() => {
        subscribe("hidden.bs.modal", onHide);
        return () => unsubscribe("hidden.bs.modal", onHide);
    }, [onHide]);

    useEffect(() => {
        if (modal.current) {
            const bs_modal = bootstrap.Modal.getOrCreateInstance(
                modal.current,
                {
                    backdrop: staticBackdrop ? "static" : true,
                    keyboard,
                    focus,
                }
            );
            if (show) bs_modal.show();
            else bs_modal.hide();
        }
    }, [focus, keyboard, staticBackdrop, show]);

    return (
        createPortal(<div
            className={`modal fade ${className}`}
            id={id}
            tabIndex="-1"
            aria-labelledby={title}
            aria-hidden="true"
            ref={modal}
        >
            <div className={`modal-dialog ${modalDialogCentered ? "modal-dialog-centered" : ''}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onHide}
                        ></button>
                    </div>
                    {children}
                </div>
            </div>
        </div>, document.getElementById("renderModalHere"))
        
    );
};

Modal.Body = ({ children }) => <div className="modal-body">{children}</div>;
Modal.Footer = ({ children }) => <div className="modal-footer">{children}</div>;

export default Modal;
