import { Form, Link, NavLink, useRouteLoaderData } from "react-router-dom";
import logo from "../../assets/images/logo2.png";
import "./Navbar.css";
import { ANALYTICS_URL } from "../../config";

const Navlink = ({ children, className, href = "#" }) => {
    return (
        <li className={`nav-item ${className}`}>
            <NavLink className="nav-link" to={href}>
                {children}
            </NavLink>
        </li>
    );
};

const Navbar = () => {
    const loggedIn = useRouteLoaderData("root") ? true : false;

    return (
        <nav className="navbar main_navbar fixed-top navbar-expand-sm bg-light">
            <div className="container-fluid px-3">
                <Link className="navbar-brand" to="/">
                    <img
                        src={logo}
                        alt="CyberSmarties"
                        width="auto"
                        height="42"
                    ></img>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_navbarNav"
                    aria-controls="main_navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_navbarNav">
                    <ul className="navbar-nav">
                        <Navlink href="/">Home</Navlink>
                        {/* <Navlink href="/register">School</Navlink> */}
                        {loggedIn && (
                            <Navlink href="/teachers">Teachers</Navlink>
                        )}
                        {loggedIn && <Navlink href="/classes">Classes</Navlink>}
                        {loggedIn && (
                            <Navlink href="/students">Students</Navlink>
                        )}
                        {loggedIn && (
                            <Navlink href={ANALYTICS_URL} target="_blank">Analytics</Navlink>
                        )}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        {loggedIn && (
                            <span id="username_display" className="navbar-text">
                                {localStorage.getItem("username")}
                            </span>
                        )}
                        {loggedIn && (
                            <li className="nav-item logout">
                                <Form method="post" action="/logout">
                                    <button
                                        type="submit"
                                        className="btn btn-outline-danger"
                                    >
                                        Logout
                                    </button>
                                </Form>
                            </li>
                        )}

                        {!loggedIn && (
                            <li className="nav-item login">
                                <Link
                                    className="btn btn-outline-success"
                                    to="/login"
                                >
                                    Login
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
