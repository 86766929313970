import { Alert, DIVISION_ALIAS, Toast } from "../../config";
import styles from "./ManageClassDivision.module.css";
import MeatBallMenu from "../UI/MeatBallMenu";
import { Link } from "react-router-dom";
import {
    delete_division,
    get_division_list,
    queryClient,
} from "../../util/http";
import { useMutation, useQuery } from "@tanstack/react-query";

const DivisionList = ({ classId, onDivisionEdit }) => {
    const {
        data: divisions,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ["divisions", { class_id: classId }],
        queryFn: get_division_list.bind(null, { class_id: classId }),
    });

    const data = divisions?.data;
    const divisionList = data?.data?.list;

    // console.log(data, divisionList);

    const { mutate } = useMutation({
        mutationFn: ({ division_id }) => delete_division(division_id),
        onSuccess: (_, { division_name }) => {
            Toast.fire({
                title: `Deleted division '${division_name}' successfully!`,
                icon: "success",
            });
            queryClient.invalidateQueries({
                queryKey: ["divisions", { class_id: classId }],
            });
        },
        onError: (error, { division_name }) => {
            Toast.fire({
                title: `Failed to delete division '${division_name}'`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error in division list", error);
        },
    });

    const deleteHandler = (e) => {
        const divisionId = e.target.getAttribute("data-id");
        const divisionName = e.target.getAttribute("data-name");
        Alert.fire({
            title: "Are you sure?",
            text: `You are about to delete division '${divisionName}'. This action is irreversible!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                mutate({
                    division_id: divisionId,
                    division_name: divisionName,
                });
            }
        });
    };
    return (
        <>
            <h4 className="text-indigo mt-4 bg-light-transparent d-inline-block">
                {DIVISION_ALIAS} List
            </h4>
            {/* <div className={`table-responsive-md mt-2 ${styles.responsive}`}> */}
            <table
                className={`table table-hover table-light ${styles["table-design-1"]} ${styles["division-list"]}`}
            >
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th className="text-center" scope="col">
                            <i className="fa-solid fa-list"></i>
                        </th>
                    </tr>
                </thead>
                <tbody className={styles["tbody-bg"]}>
                    {isPending ? (
                        <tr>
                            <td className="text-center" colSpan="3">
                                Loading...
                            </td>
                        </tr>
                    ) : isError ? (
                        <tr>
                            <td className="text-center" colSpan="3">
                                {error.name}
                                {": "}
                                {error.message}
                            </td>
                        </tr>
                    ) : data.code === 2 ? (
                        <tr>
                            <td className="text-center" colSpan="3">
                                No data to display
                            </td>
                        </tr>
                    ) : data.code === 1 ? (
                        divisionList.map((division) => {
                            return (
                                <tr key={division.id}>
                                    <th scope="row">{division.id}</th>
                                    <td>{division.name}</td>
                                    <td className="text-center">
                                        <MeatBallMenu>
                                            <li>
                                                <Link
                                                    className="dropdown-item edit"
                                                    type="button"
                                                    to={`${division.class_id}/divisions/${division.id}`}
                                                >
                                                    Manage
                                                </Link>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item edit"
                                                    type="button"
                                                    onClick={onDivisionEdit.bind(
                                                        null,
                                                        {
                                                            id: division.id,
                                                            name: division.name,
                                                        }
                                                    )}
                                                >
                                                    Edit
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item delete"
                                                    type="button"
                                                    onClick={deleteHandler}
                                                    data-id={division.id}
                                                    data-name={division.name}
                                                >
                                                    Delete
                                                </button>
                                            </li>
                                        </MeatBallMenu>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="3">
                                No data to display
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {/* </div> */}
        </>
    );
};

export default DivisionList;
