import { useMutation, useQuery } from "@tanstack/react-query";
import { delete_user, get_user_list, queryClient } from "../../util/http";
import MeatBallMenu from "../UI/MeatBallMenu";
import styles from "./ManageTeacher.module.css";
import { toSentenceCase } from "../../util/misc";
import { Alert, Toast } from "../../config";

const TeacherList = ({ onEdit }) => {
    const {
        data: response,
        isPending,
        isError,
        error,
    } = useQuery({
        queryKey: ["teachers"],
        queryFn: get_user_list.bind(null, "teacher", {
            sort_by: "id",
            order_by: "desc",
        }),
    });
    const data = response ? response.data : null;
    // console.log(data);

    const { mutate } = useMutation({
        mutationFn: ({ user_id }) => delete_user(user_id),
        onSuccess: (_, { username }) => {
            Toast.fire({
                title: `Deleted ${username} successfully!`,
                icon: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["teachers"] });
        },
        onError: (error, { username }) => {
            Toast.fire({
                title: `Failed to delete ${username}`,
                text: `${error.name}: ${error.message}`,
                icon: "error",
            });
            console.log("error in teacher list", error);
        },
    });

    const deleteHandler = (e) => {
        const teacherId = e.target.getAttribute("data-id");
        const teacherUsername = e.target.getAttribute("data-username");
        Alert.fire({
            title: "Are you sure?",
            text: `You are about to delete the user '${teacherUsername}'. This action is irreversible!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                mutate({ user_id: teacherId, username: teacherUsername });
            }
        });
    };

    return (
        <>
            <h4 className="text-indigo mt-5">Teacher Accounts</h4>
            <div className={`table-responsive-md mt-2 ${styles.responsive}`}>
                <table
                    className={`table table-hover table-light ${styles["teacher-list"]}`}
                >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Username</th>
                            <th className="text-center" scope="col">
                                Status
                            </th>
                            <th className="text-center" scope="col">
                                <i className="fa-solid fa-list"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles["tbody-bg"]}>
                        {isPending ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    Loading...
                                </td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    {error.name}
                                    {": "}
                                    {error.message}
                                </td>
                            </tr>
                        ) : data.code === 2 ? (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    No data to display
                                </td>
                            </tr>
                        ) : data.code === 1 ? (
                            data.data.list.map((teacher) => {
                                return (
                                    <tr key={teacher.id}>
                                        <th scope="row">{teacher.id}</th>
                                        <td>{teacher.name}</td>
                                        <td>{teacher.email}</td>
                                        <td>{teacher.phone_number}</td>
                                        <td>{teacher.username}</td>
                                        <td className="text-center">
                                            <span
                                                className={`badge fw-normal rounded-pill ${
                                                    teacher.status === "active"
                                                        ? "text-bg-success"
                                                        : "text-bg-danger"
                                                }`}
                                            >
                                                {toSentenceCase(teacher.status)}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <MeatBallMenu>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={onEdit.bind(
                                                            null,
                                                            {
                                                                id: teacher.id,
                                                                name: teacher.name,
                                                                email: teacher.email,
                                                                phone: teacher.phone_number,
                                                                username:
                                                                    teacher.username,
                                                                password:
                                                                    teacher.password,
                                                            }
                                                        )}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        data-id={teacher.id}
                                                        data-username={
                                                            teacher.username
                                                        }
                                                        onClick={deleteHandler}
                                                        className="dropdown-item"
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            </MeatBallMenu>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="7">
                                    Unknown response!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TeacherList;
