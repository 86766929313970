import { useState } from "react";
import StudentInfoForm from "./StudentInfoForm";
import StudentList from "./StudentList";

const ManageStudent = () => {

    const [resetTeacherInfoFormKey, setResetTeacherInfoFormKey] =
        useState(false);
    const resetTeacherInfoForm = () =>
        setResetTeacherInfoFormKey(!resetTeacherInfoFormKey);

    return (
        <div className="my-4">
            <h2 className="h2 mb-4">Manage Student Accounts</h2>
            <StudentInfoForm
                key={resetTeacherInfoFormKey}
                reset={resetTeacherInfoForm}
            />
            <StudentList />
        </div>
    );
};
export default ManageStudent;
