import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Modal from "../UI/Modal";
import { useQuery } from "@tanstack/react-query";
import { get_user_list } from "../../util/http";

const roleOptions = [
    { value: 1, label: "Teacher" },
    { value: 2, label: "Class teacher" },
];

export const AddExistingTeacher = ({ show, onHide, onSave }) => {
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [role, setRole] = useState(null);

    const { data: teachers } = useQuery({
        queryKey: ["teachers"],
        queryFn: get_user_list.bind(null, "teacher", {
            sort_by: "name",
            order_by: "asc",
        }),
    });

    const teacherList = useMemo(
        () =>
            teachers?.data?.data?.list
                ? teachers.data.data.list.map((teacher) => ({
                      value: teacher.id,
                      label: `${teacher.name} - ${teacher.username}`,
                  }))
                : [],
        [teachers]
    );

    useEffect(() => {
        if (show) {
            setSelectedTeachers([]);
            setRole(null);
        }
    }, [show]);

    const teacherSelectChangeHandler = (newValue) =>
        setSelectedTeachers(newValue);

    const roleChangeHandler = (newValue) => {
        setRole(newValue);
    };

    const saveClickHandler = () => {
        if (!selectedTeachers) return;
        const teacher_ids = selectedTeachers.map((teacher) => teacher.value);
        if (role.value === 1) onSave({ teacher_ids });
        else onSave({ class_teacher_ids: teacher_ids });
        onHide();
    };

    return (
        <Modal
            title="Add existing teachers"
            className="modal-lg"
            modalDialogCentered={true}
            show={show}
            onHide={onHide}
        >
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8">
                            <Select
                                isMulti
                                name="teachers"
                                options={teacherList}
                                value={selectedTeachers}
                                classNamePrefix="react-select-bootstrap"
                                placeholder="Select teachers to add"
                                onChange={teacherSelectChangeHandler}
                            />
                        </div>
                        <div className="col">
                            <Select
                                name="role"
                                options={roleOptions}
                                value={role}
                                classNamePrefix="react-select-bootstrap"
                                placeholder="Select role"
                                onChange={roleChangeHandler}
                            ></Select>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onHide}
                >
                    Close
                </button>
                <button
                    type="button"
                    onClick={saveClickHandler}
                    className="btn btn-primary"
                >
                    Save changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export const AddExistingStudent = ({ show, onHide, onSave }) => {
    const [selectedStudents, setSelectedStudents] = useState([]);

    const { data: students } = useQuery({
        queryKey: [
            "students",
            {
                sort_by: "username",
                order_by: "asc",
            },
        ],
        queryFn: get_user_list.bind(null, "student", {
            sort_by: "username",
            order_by: "asc",
        }),
    });

    const studentList = useMemo(
        () =>
            students?.data?.data?.list
                ? students.data.data.list.map((student) => ({
                      value: student.id,
                      label: student.username,
                  }))
                : [],
        [students]
    );

    // console.log(studentList);

    useEffect(() => {
        if (show) setSelectedStudents([]);
    }, [show]);

    const studentSelectChangeHandler = (newValue) =>
        setSelectedStudents(newValue);

    const saveClickHandler = () => {
        if (!selectedStudents) return;
        const student_ids_string = selectedStudents
            .map((student) => student.value)
            .toString();
        // console.log(student_ids_string);
        onSave(student_ids_string);
        onHide();
    };

    return (
        <Modal
            title="Add existing students"
            modalDialogCentered={true}
            show={show}
            onHide={onHide}
        >
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Select
                                isMulti
                                name="students"
                                options={studentList}
                                value={selectedStudents}
                                classNamePrefix="react-select-bootstrap"
                                placeholder="Select students to add"
                                onChange={studentSelectChangeHandler}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onHide}
                >
                    Close
                </button>
                <button
                    onClick={saveClickHandler}
                    type="button"
                    className="btn btn-primary"
                >
                    Save changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};
