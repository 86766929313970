import { useEffect, useRef, useState } from "react";

const FloatingInput = ({
    className,
    type,
    id,
    placeholder = undefined,
    label,
    feedback = undefined,
    valid = undefined,
    ...otherProps
}) => {
    placeholder = !placeholder ? label : placeholder;
    const feedbackElementId = id + "Feedback";
    const inputElement = useRef(null);
    const feedbackElement = useRef(null);
    const [feedbackElementMargin, setFeedbackElementMargin] = useState({
        top: "0px",
        bottom: "0px",
    });
    const ariaDescribedby =
        feedback && feedback.length
            ? { "aria-describedby": feedbackElementId }
            : {};

    const validityClass =
        valid === true ? "valid" : valid === false ? "invalid" : "";
    // console.log(feedbackElementMargin, feedbackElement);

    useEffect(() => {
        // console.log("effect", feedbackElement.current);
        if (feedbackElement.current && feedbackElement.current.clientHeight) {
            const style =
                inputElement.current.currentStyle ||
                window.getComputedStyle(inputElement.current);
            setFeedbackElementMargin({
                top: style.marginBottom,
                bottom: `${feedbackElement.current.clientHeight}px`,
            });
        }
    }, [feedback]);

    return (
        <div className="form-floating d-flex flex-column">
            <input
                ref={inputElement}
                type={type}
                className={`form-control ${className} is-${validityClass}`}
                id={id}
                placeholder={placeholder}
                {...ariaDescribedby}
                {...otherProps}
            />
            <label htmlFor={id}>{label}</label>
            {feedback && feedback.length && (
                <div
                    ref={feedbackElement}
                    id={feedbackElementId}
                    className={`${validityClass}-feedback text-start ms-1`}
                    style={{
                        marginTop: `calc(-${feedbackElementMargin.top} + 0.25rem)`,
                        marginBottom:
                            feedbackElementMargin.top >
                            feedbackElementMargin.bottom
                                ? `calc(${feedbackElementMargin.top} -
                            ${feedbackElementMargin.bottom})`
                                : "0.25rem",
                    }}
                >
                    {feedback}
                </div>
            )}
        </div>
    );
};

export default FloatingInput;
