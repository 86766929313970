import { useState } from "react";
import "./MeatBallMenu.css";
import "./common.css"

const MeatBallMenu = ({children}) => {
    const [buttonMouseOver, setButtonMouseOver] = useState(false);
    return (
        <div className="dropdown meatball-menu">
            <button
                className="menu-toggle button-reset"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onMouseEnter={()=>setButtonMouseOver(true)}
                onMouseLeave={()=>setButtonMouseOver(false)}
                // onClick={(e)=>{e.stopPropagation();}}
            >
                <i className={`fa-solid fa-ellipsis ${buttonMouseOver && 'fa-shake'}`}></i>
            </button>
            <ul className="dropdown-menu noselect">
                {children}
            </ul>
        </div>
    );
};

export default MeatBallMenu;
