import Jumbotron from "./UI/Jumbotron";
import styles from "./Dashboard.module.css";
import { useContext, useLayoutEffect } from "react";
import StyleClassContext from "../store/StylesContext";
import { useQuery } from "@tanstack/react-query";
import { get_school_dashboard_stats, get_school_detail } from "../util/http";

const Dashboard = () => {
    const setPageBackgroundClass = useContext(StyleClassContext);
    const { data: response, isPending } = useQuery({
        queryKey: ["school_stats"],
        queryFn: get_school_dashboard_stats,
    });
    const data = response ? response.data : null;
    console.log(data);

    const { data: schoolDetailResponse, isPending: isSchoolInfoPending } =
        useQuery({
            queryKey: ["school_details"],
            queryFn: get_school_detail,
        });
    const schoolData = schoolDetailResponse ? schoolDetailResponse.data : null;
    console.log(schoolData);
    useLayoutEffect(() => {
        setPageBackgroundClass(styles.bg);
        return () => {
            setPageBackgroundClass("");
        };
    }, [setPageBackgroundClass]);
    return (
        <>
            <div className="row mx-xxl-5">
                <div className="col">
                    {" "}
                    <Jumbotron
                        className="bg-secondary-transparent mt-3 mx-auto"
                        backgroundColor
                        py={3}
                    >
                        <h1>{localStorage.getItem("school_name")}</h1>
                    </Jumbotron>
                </div>
            </div>
            <div className="row gy-4 mb-3 mx-xxl-5">
                <div className="col col-lg-6">
                    <Jumbotron
                        className="bg-secondary-transparent mx-auto h-100"
                        backgroundColor
                        py={4}
                    >
                        {isPending ? (
                            <div className="d-flex justify-content-center">
                                <div
                                    className="spinner-border"
                                    style={{ width: "3rem", height: "3rem" }}
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="row gy-4">
                                <div className="col-sm-6 col-lg-6">
                                    <div
                                        className={`${styles.tile_counter} bg-sky rounded-2 mx-auto`}
                                    >
                                        <div className={`${styles.icon}`}>
                                            <i className="fa-solid fa-school"></i>
                                        </div>
                                        <div className={`${styles.text}`}>
                                            <div className={`${styles.number}`}>
                                                {data.data.total_class}
                                            </div>
                                            <div className={`${styles.name}`}>
                                                Classes
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div
                                        className={`${styles.tile_counter} bg-lime rounded-2 mx-auto`}
                                    >
                                        <div className={`${styles.icon}`}>
                                            <i className="fa-solid fa-door-open"></i>
                                        </div>
                                        <div className={`${styles.text}`}>
                                            <div className={`${styles.number}`}>
                                                {data.data.total_division}
                                            </div>
                                            <div className={`${styles.name}`}>
                                                Divisions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div
                                        className={`${styles.tile_counter} bg-indigo rounded-2 mx-auto`}
                                    >
                                        <div className={`${styles.icon}`}>
                                            <i className="fa-solid fa-person-chalkboard"></i>
                                        </div>
                                        <div className={`${styles.text}`}>
                                            <div className={`${styles.number}`}>
                                                {data.data.total_teacher}
                                            </div>
                                            <div className={`${styles.name}`}>
                                                Teachers
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div
                                        className={`${styles.tile_counter} bg-brinjal rounded-2 mx-auto`}
                                    >
                                        <div className={`${styles.icon}`}>
                                            <i className="fa-solid fa-book-open-reader"></i>
                                        </div>
                                        <div className={`${styles.text}`}>
                                            <div className={`${styles.number}`}>
                                                {data.data.total_student}
                                            </div>
                                            <div className={`${styles.name}`}>
                                                Students
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Jumbotron>
                </div>
                <div className="col-lg-6">
                    {isSchoolInfoPending ? (
                        <Jumbotron
                            className="bg-secondary-transparent mx-auto h-100"
                            backgroundColor
                            py={4}
                        >
                            <div className="d-flex justify-content-center">
                                <div
                                    className="spinner-border"
                                    style={{ width: "3rem", height: "3rem" }}
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            </div>
                        </Jumbotron>
                    ) : (
                        <ul className="list-group rounded-3">
                            <li className="list-group-item list-group-item-action d-flex gap-3 py-2 bg-secondary-transparent border-bottom-1">
                                <div className={styles.list_group_icon}>
                                    <i className="fa-solid fa-school-flag fa-xl"></i>
                                </div>

                                <div className="d-flex gap-2 w-100 justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-indigo">
                                            School name
                                        </h6>
                                        <p className="mb-0">
                                            {schoolData.data.name}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item list-group-item-action d-flex gap-3 py-2 bg-secondary-transparent border-bottom-1">
                                <div className={styles.list_group_icon}>
                                    <i className="fa-solid fa-id-card fa-xl"></i>
                                </div>

                                <div className="d-flex gap-2 w-100 justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-indigo">
                                            School ID/Code
                                        </h6>
                                        <p className="mb-0">
                                            {schoolData.data.school_code}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item list-group-item-action d-flex gap-3 py-2 bg-secondary-transparent border-bottom-1">
                                <div className={styles.list_group_icon}>
                                    <i className="fa-solid fa-envelope fa-xl"></i>
                                </div>

                                <div className="d-flex gap-2 w-100 justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-indigo">
                                            Email
                                        </h6>
                                        <p className="mb-0">
                                            {schoolData.data.email}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item list-group-item-action d-flex gap-3 py-2 bg-secondary-transparent border-bottom-1">
                                <div className={styles.list_group_icon}>
                                    <i className="fa-solid fa-mobile-screen-button fa-xl"></i>
                                </div>

                                <div className="d-flex gap-2 w-100 justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-indigo">
                                            Phone number
                                        </h6>
                                        <p className="mb-0">
                                            {schoolData.data.phone_number}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item list-group-item-action d-flex gap-3 py-2 bg-secondary-transparent">
                                <div className={styles.list_group_icon}>
                                    <i className="fa-solid fa-map-location-dot fa-xl"></i>
                                </div>

                                <div className="d-flex gap-2 w-100 justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-indigo">
                                            Address
                                        </h6>
                                        <p className="mb-0">
                                            {schoolData.data.location}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
