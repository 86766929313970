import MeatBallMenu from "../UI/MeatBallMenu";
import styles from "./ManageRoom.module.css";
import { AddExistingTeacher } from "./AddUserModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { queryClient, update_division } from "../../util/http";
import { DIVISION_ALIAS, Toast } from "../../config";
import { useMutation } from "@tanstack/react-query";

const RoomTeachers = ({ divisionInfo }) => {
    const teacherList = divisionInfo.teacher_list;
    const division_id = divisionInfo.id;
    const [show, setShow] = useState(false);
    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);

    const { mutate } = useMutation({
        mutationFn: update_division,
    });

    const onAddTeacherSaveHandler = (data) => {
        const teacher_ids = data?.teacher_ids?.length
            ? teacherList.flatMap((teacher) =>
                  teacher.role === "teacher" ? teacher.teacher_id : []
              )
            : teacherList.flatMap((teacher) =>
                  teacher.role === "class_teacher" ? teacher.teacher_id : []
              );

        data = data?.teacher_ids?.length
            ? {
                  teacher_ids: [
                      ...new Set(data.teacher_ids.concat(teacher_ids)), // This ugly code to merge two arrays without duplicates
                  ],
              }
            : {
                  class_teacher_ids: [
                      ...new Set(data.class_teacher_ids.concat(teacher_ids)), // This ugly code to merge two arrays without duplicates
                  ],
              };
        console.log("mutate", data);
        mutate(
            { ...data, division_id: division_id, name: divisionInfo.name },
            {
                onSuccess: () => {
                    Toast.fire({
                        title: `Added teacher to ${DIVISION_ALIAS.toLowerCase()}`,
                        icon: "success",
                    });

                    setTimeout(
                        () =>
                            queryClient.invalidateQueries({
                                queryKey: [
                                    "divisions",
                                    { division_id: division_id },
                                ],
                            }),
                        1000
                    );
                },
                onError: (error) => {
                    Toast.fire({
                        title: `Failed to add teacher to ${DIVISION_ALIAS.toLowerCase()}`,
                        text: `${error.name}: ${error.message}`,
                        icon: "error",
                    });
                    console.log("error in add division teacher", error);
                },
            }
        );
    };

    const teacherRemoveSuccessHandler = () => {
        Toast.fire({
            title: `Removed teacher(s) from ${DIVISION_ALIAS.toLowerCase()}`,
            icon: "success",
        });

        setTimeout(
            () =>
                queryClient.invalidateQueries({
                    queryKey: ["divisions", { division_id: division_id }],
                }),
            1000
        );
    };

    const teacherRemoveErrorHandler = (error) => {
        Toast.fire({
            title: `Failed to remove teacher(s) from ${DIVISION_ALIAS.toLowerCase()}`,
            text: `${error.name}: ${error.message}`,
            icon: "error",
        });
        console.log("error in add division teacher", error);
    };

    const removeAllClickHandler = () => {
        if(!teacherList.length) return;
        const payload = {
            division_id: division_id,
            name: divisionInfo.name,
            class_teacher_ids: [],
            teacher_ids: [],
        };
        mutate(payload, {
            onSuccess: teacherRemoveSuccessHandler,
            onError: teacherRemoveErrorHandler,
        });
    };

    const removeClickHandler = (e) => {
        const teacherId = parseInt(e.target.getAttribute("data-id"));
        const teacherRole = e.target.getAttribute("data-role");
        console.log(teacherList, teacherId);
        const teacher_ids =
            teacherRole === "teacher"
                ? teacherList.flatMap((teacher) =>
                      teacher.role === "teacher" &&
                      teacher.teacher_id !== teacherId
                          ? teacher.teacher_id
                          : []
                  )
                : teacherList.flatMap((teacher) =>
                      teacher.role === "class_teacher" &&
                      teacher.teacher_id !== teacherId
                          ? teacher.teacher_id
                          : []
                  );
        console.log("mutate", teacher_ids);

        const payload = { division_id: division_id, name: divisionInfo.name };

        if (teacherRole === "teacher")
            mutate(
                { ...payload, teacher_ids },
                {
                    onSuccess: teacherRemoveSuccessHandler,
                    onError: teacherRemoveErrorHandler,
                }
            );
        else
            mutate(
                { ...payload, class_teacher_ids: teacher_ids },
                {
                    onSuccess: teacherRemoveSuccessHandler,
                    onError: teacherRemoveErrorHandler,
                }
            );
    };

    return (
        <>
            <AddExistingTeacher
                onSave={onAddTeacherSaveHandler}
                show={show}
                onHide={hideModal}
            />
            <h4 className="text-indigo mt-5">Teacher Accounts</h4>
            <div className="mt-2">
                <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={showModal}
                >
                    Add existing teachers
                </button>
                <button
                    className="btn btn-sm btn-secondary ms-1"
                    type="button"
                    onClick={removeAllClickHandler}
                >
                    Remove all
                </button>
                <Link
                    className="btn btn-sm btn-link ms-1"
                    type="button"
                    to="/teachers"
                >
                    Add new teacher
                </Link>
            </div>
            <div className={`table-responsive-md mt-2 ${styles.responsive}`}>
                <table
                    className={`table table-hover table-light ${styles["teacher-list"]}`}
                >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Username</th>
                            <th scope="col">Role</th>
                            <th className="text-center" scope="col">
                                <i className="fa-solid fa-list"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={styles["tbody-bg"]}>
                        {teacherList && teacherList.length ? (
                            teacherList.map((teacher) => {
                                return (
                                    <tr key={teacher.id}>
                                        <th scope="row">
                                            {teacher.teacher_id}
                                        </th>
                                        <td>{teacher.teacher}</td>
                                        <td>{teacher.username}</td>
                                        <td>{teacher.role}</td>
                                        <td className="text-center">
                                            <MeatBallMenu>
                                                <li>
                                                    <button
                                                        data-id={
                                                            teacher.teacher_id
                                                        }
                                                        data-role={teacher.role}
                                                        className="dropdown-item"
                                                        onClick={
                                                            removeClickHandler
                                                        }
                                                    >
                                                        Remove
                                                    </button>
                                                </li>
                                            </MeatBallMenu>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td className="text-center" colSpan="5">
                                    No teachers added to this division
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default RoomTeachers;
